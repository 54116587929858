'use strict'

###*
 # @ngdoc object
 # @name mundoAuthentication.controller:UserActivationCtrl

 # @description

###
class UserActivationCtrl
  ### @ngInject ###
  constructor: ($stateParams, Restangular, OAuth, MyUser) ->
    @ctrlName = 'UserActivationCtrl'

    @entity =
      token: $stateParams.token
      plainPassword: null
      plainPasswordVerification: null

    @errorMessage = null

    @activate = () =>
      @errorMessage = null

      Restangular.all 'open/users/activate'
        .customPOST
          token: @entity.token
        .then (activationResult) =>
          @entity.token = activationResult.token

          Restangular.all 'open/users/password/reset/confirm'
            .customPOST
              token: @entity.token
              plainPassword: @entity.plainPassword
            .then (userResult) =>
              OAuth
                .getAccessToken
                  username: userResult.username
                  password: @entity.plainPassword
                .then (tokenResult) =>
                  MyUser.one()
                    .patch
                      locale: 'nl'
                    .then (patchResult) =>
                      @entity = userResult
                      @errorMessage = null
                    , () =>
                      @entity = userResult
                      @errorMessage = null
            , () =>
              @errorMessage = 'app.errors.could-not-set-password'
        , () =>
          @errorMessage = 'app.errors.could-not-activate-account'

angular
  .module('mundoAuthentication')
  .controller 'UserActivationCtrl', UserActivationCtrl
